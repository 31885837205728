import { delete_cookie } from "../read_cookie";
import { read_cookie } from "../read_cookie";

const Auth = {
  isAuthenticated: false,

  authenticate() {
    try {
      const cookieToken = read_cookie("token");
      const isValidToken = typeof cookieToken === "string" && cookieToken.trim() !== "";

      if (isValidToken) {
        this.isAuthenticated = true;
      } else {
        this.isAuthenticated = false;
        delete_cookie("token");
        sessionStorage.removeItem("accessToken");
      }
    } catch (error) {
      delete_cookie("token");
      sessionStorage.removeItem("accessToken");
      this.isAuthenticated = false;
    }
  },

  signout() {
    this.isAuthenticated = false;
    delete_cookie("token");
    sessionStorage.removeItem("accessToken");
  },

  getAuth() {
    return this.isAuthenticated;
  },
};

export default Auth;
