import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import $ from "jquery";
import { useStoreState } from "easy-peasy";
import config from "../../configs/config";
import { read_cookie } from "../../read_cookie";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ReactSnackBar from "../snackbar/snackBar";

const MyCommunication = (props) => {
  document.title = props.title;
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const watchEmail = watch("email", "");
  const [checkedState, setCheckedState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [optsData, setOptsData] = useState([]);
  const getUserValue = useStoreState((state) => state.user.userInfo);
  let profile = getUserValue ? getUserValue : [];
  const URL = config.qa.node_url;
  const [Show, setShow] = useState(false);

  const getopts = () => {
    $("input.disablecopypaste").bind("copy paste", function (e) {
      e.preventDefault();
    });
    setLoading(true);
    let profileId = read_cookie("profileId");
    let apiUrl = `opts?profileId=${profileId}`;
    let requestData = { ProfileId: profileId };
    let header = { programcode: "RTL_TVL", token: sessionStorage.accessToken };

    axios
      .post(URL + apiUrl, requestData, { headers: header })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          let opts = response.data;
          setCheckedState(opts.ElectronicDisclosure === "Y");
          $("#email").val(opts.Email);
          setOptsData(opts);
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getopts();
  }, []);

  const getValue = (value) => value === watchEmail;

  const updateCheckbox = () => {
    setCheckedState(!checkedState);
  };

  const emailMatch = (e) => {
    if (e.target.value !== "" && e.target.value !== watchEmail) {
      setError("confirmEmail", { type: "notMatch", message: "The email addresses do not match" });
    } else {
      clearErrors("confirmEmail");
    }
  };

  const emailTooltip = (
    <Tooltip id="email-tooltip">
      <strong>Email Requirements:</strong> This is the email you chose for contact purposes.
    </Tooltip>
  );

  const getPreferredEmail = () => {
    if (getUserValue?.Emails) {
      const prefEmailArr = getUserValue.Emails.filter((el) => el.IsPreferred);
      return prefEmailArr.length > 0 ? prefEmailArr[0].EmailAddress : "";
    }
    return "";
  };

  const onSubmit = (data) => {
    if (data.email === data.confirmEmail) {
      setLoading(true);
      let profileId = profile.ProfileId;
      let apiUrl = `communication?profileId=${profileId}`;
      let optInOut = checkedState ? "Y" : "N";
      let now = new Date();
      let ActivityDate = now.toISOString();
      let requestData = {
        Emails: [
          {
            EmailAddress: data.email,
            ActivityDate: ActivityDate,
            IsPreferred: true,
            JsonExternalData: {
              ElectronicDisclosure: optInOut,
            },
          },
        ],
      };
      let header = {
        programcode: "RTL_TVL",
        token: sessionStorage.accessToken,
      };
      axios
        .put(URL + apiUrl, requestData, { headers: header })
        .then(() => {
          setLoading(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
            document.getElementById("formMycommunication").reset();
            getopts();
          }, 5000);
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div className="container">
      {loading && <div className="loader"></div>}
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="row pnc-my-coomunication">
            <div className="col-md-12">
              <h3 aria-level="1">My Communications</h3>
              <p>
                Want to hear more from PNC Cash Unlimited? Choose to receive
                emails below and feel free to change your preferences whenever
                you want.
              </p>
              <div className="pnc-mycom-form">
                <p className="common-question">Paperless Delivery</p>
                <p>
                  Be sure to keep the email address in your profile current in
                  order to receive emails from us. It is important that we keep
                  you up-to-date with our latest terms and conditions. If you
                  prefer to receive the updates electronically to the email
                  address saved in your profile, please choose that option
                  below.
                </p>

                <form id="formMycommunication" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="electronic-program"
                        role="check-box"
                        aria-label="check-box"
                        checked={checkedState}
                        onChange={updateCheckbox}
                      />
                      <label className="electronic-program-check" htmlFor="electronic-program">
                        Yes, please send me electronic program disclosures.
                      </label>
                    </div>

                    <div className="pnc-mycom-mail">
                      <div className={`form-group ${errors.email ? "has-error" : ""}`}>
                        <label htmlFor="email" className="disableLabel">
                          Email *
                        </label>
                        <OverlayTrigger placement="right" overlay={emailTooltip}>
                          <img src="/images/tootip.svg" alt="tooltip" className="pnc-tooltip" />
                        </OverlayTrigger>
                        <input
                          id="email"
                          name="email"
                          autoComplete="off"
                          className="form-control disablecopypaste"
                          defaultValue={getUserValue.Emails ? getPreferredEmail() : ""}
                          type="text"
                          {...register("email", {
                            required: "Please enter a valid email address",
                            pattern: {
                              value: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/i,
                              message: "You have entered an invalid email address!",
                            },
                          })}
                        />
                        <div className="help-block with-errors">
                          {errors.email && errors.email.message}
                        </div>
                      </div>
                      <div className={`form-group ${errors.confirmEmail ? "has-error" : ""}`}>
                        <label htmlFor="confirmEmail" className="disableLabel">
                          Confirm Email *
                        </label>
                        <input
                          id="confirmEmail"
                          name="confirmEmail"
                          autoComplete="off"
                          className="form-control disablecopypaste"
                          type="text"
                          {...register("confirmEmail", {
                            required: "The email addresses entered do not match. Please try again.",
                            validate: (value) =>
                              getValue(value) || "The email addresses entered do not match",
                          })}
                          onChange={emailMatch}
                        />
                        <div className="help-block with-errors">
                          {errors.confirmEmail && errors.confirmEmail.message}
                        </div>
                      </div>
                    </div>
                    <div className="pnc-btn-wrap">
                      <button type="button" onClick={() => getopts()} className="btn cancel">
                        Cancel
                      </button>
                      <button type="submit" className="btn update">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactSnackBar Show={Show}>Your Profile Has Been Updated</ReactSnackBar>
    </div>
  );
};

export default MyCommunication;
