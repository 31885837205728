import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { delete_cookie } from "../read_cookie";
import { bake_cookie } from "../read_cookie";
const Redirect = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  let fullEncStr = params.id;
  delete_cookie("pnc-rewards-token");
  delete_cookie("profileId");
  delete_cookie("ProgramCode");
  // sessionStorage.clear();
  // localStorage.clear();
  if (fullEncStr != 0) {
    let encArr = fullEncStr.split("&&");
    //  console.log(encArr)
    let token = encArr[0].toString();
    let profileId = encArr[1].toString();
    let ProgramCode = encArr[2].toString();
    bake_cookie("pnc-rewards-token", token);
    bake_cookie("profileId", profileId);
    bake_cookie("ProgramCode", ProgramCode);
  }

  useEffect(() => {
    // Perform navigation after all actions
    navigate(`/`);
  }, [params, navigate]);

  return (
    <section className="pnc-help-section">
      <div className="loader"></div>
    </section>
  );
};

export default Redirect;
